import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import ArrowBg from "./ArrowBg";

import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

const Hero = styled.div `
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 450px;
  background: #222;
  background-size: cover;
  background-position: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Courier Prime', monospace;
  .title {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    font-size: 80px;
    font-weight: bold;
    text-align: center;
    background-color: rgba(0,0,0,.6);
    border-radius: 10px;
    padding: 0 20px;
    z-index: 1;
  }

  @media (max-width: 650px) {
    height: 350px;
    .title {
      font-size: 40px;
    }
  }
`;
const Logo = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  background-size: cover;
  mask-size: cover;
  mask-position: 50%;
  background-color: white;
  mask-image: url(${process.env.PUBLIC_URL}/knockout.svg);
  vertical-align: middle;
  margin: 0 -20px;

  @media (max-width: 800px) {
    display: block;
    margin: 0 auto;
  }
  @media (max-width: 650px) {
    width: 40px;
    height: 40px;
  }
`;
const CartWrapper = styled(Link)`
  position: absolute;
  background: black;
  border: 2px solid white;
  top: 40px;
  right: 40px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  &:hover { color: #ff0; }

  @media (max-width: 650px) {
    top: 20px;
    right: 20px;
  }
`;

const HeroBg = ({ quantity }) => (
  <Hero>
    <ArrowBg />
    <div className="title">unicode <Logo /> arrows</div>
    {quantity > 0 && (
      <CartWrapper to="/cart">
        <ShoppingCartOutlinedIcon />
      </CartWrapper>
    )}
  </Hero>
);

export default HeroBg;