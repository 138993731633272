import React, { useState, useEffect, useRef } from 'react';
import styled/*, { keyframes }*/ from 'styled-components';

// const fadeIn = keyframes`
//   0% {opacity:.2;}
//   50% {opacity:1;}
//   100% {opacity:.2;}
// `;
const BgArrow = styled.div `
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`;

const ArrowTile = ({ arrow }) => {

  const canvas = useRef();
  const [dataURL, setDataURL] = useState();

  useEffect(() => {
    const char = String.fromCharCode(`0x${arrow.split(" ")[0].split("+")[1]}`);
    var canvas = document.createElement("canvas");
    var fontSize = 60;
    canvas.setAttribute('height', fontSize);
    canvas.setAttribute('width', fontSize);
    var context = canvas.getContext('2d');
    context.fillStyle    = '#444';
    context.font         = fontSize + 'px sans-serif';
    context.fillText(char, 0, fontSize);
    setDataURL(canvas.toDataURL("image/png"));
  }, [arrow]);

  return (
    <BgArrow style={{ backgroundImage: `url(${dataURL})` }}>
      <canvas ref={canvas} />
    </BgArrow>
  );
};
export default ArrowTile;