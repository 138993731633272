import React from 'react';
import styled from 'styled-components';

const Middle = styled.div`
  font-size: 90px;
  line-height: 100px;
  overflow: hidden;

  color: transparent;
  display: flex;
  justify-content: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #888;
  .right {
    display: block;
    transform: scaleX(-1);
  }
  > span { margin-right: 80px; }
  > span:last-child { margin-right: 0; }

  @media (max-width: 650px) {
    font-size: 40px;
    line-height: 60px;
    > span { margin-right: 20px; }
  }
`;

const ArrowDivider = ({ marginTop }) => (
  <Middle style={{ marginTop }}>
    <span className="right">⟿</span>
    <span className="right">⟿</span>
    <span className="right">⟿</span>
    <span>⟿</span>
    <span>⟿</span>
    <span>⟿</span>
  </Middle>
);

export default ArrowDivider;