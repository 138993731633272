import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';

const Wrapper = styled.div `
  display: grid;
  grid-template-columns: repeat(${props => props.orientation === "landscape" ? 2 : 4}, 1fr);
  grid-gap: 40px;
  > a {
    text-decoration: none;
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(${props => props.orientation === "landscape" ? 1 : 2}, 1fr);
    grid-gap: 20px;
  }
`;
const LargeIMG = styled.div `
  background-image: url(${props => props.img});
  background-color: #ddd;
  width: 100%;
  padding-bottom: ${props => props.orientation === "landscape" ? 67 : 133}%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: inline-block;
`;
const ImgWrapper = styled.div `
  border-bottom: 3px solid ${props => props.borderColor};
  display: flex;
  position: relative;
`;
const SoldOut = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #888;
  color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:after {
    content: "sold out";
  }
`;
const Title = styled.div `
  color: black;
  text-decoration-color: #FF7400;
  margin-top: 10px;
  @media (max-width: 650px) {
    font-size: 14px;
  }
`;
const Price = styled.span `
  display: block;
  color: #888;
  font-size: 14px;
  margin-top: 5px;
`;

function ProductList(props) {
  const [products, setProducts] = useState(props.products);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    fetch('/product-info/')
      .then(res => res.json())
      .then(skus => {
        let newPrices = [];
        products.forEach(product => {
          const sku = skus.find(s => s.product === product.stripe_id);
          if (sku)
            newPrices.push({
              stripe_id: product.stripe_id,
              price: (sku.price / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
              isSoldOut: sku.inventory.quantity === 0
            });

          // let skuList = [...skus]

          // skuList = skuList.filter(s => s.product === product.stripe_id)
          //   .map(s => s.price / 100)
          // if (skuList.length === 1) {
          //   product["price"] = skuList[0].toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          // } else {
          //   let min = Math.min(...skuList),
          //     max = Math.max(...skuList);
          //   if (min === max) product["price"] = skuList[0].toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          //   else product["price"] = `${min.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} - ${max.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
          // }
        })
        setPrices(newPrices)
      }).catch(error => console.error('Error:', error))
  }, [props.products]);

  const displayProducts = [...products].filter(p => p.is_live);

  return (
    <Wrapper orientation={props.orientation}>
      { displayProducts.map((product,i) => {
        const url = (product.photos && !!product.photos.length) 
          ? `${process.env.PUBLIC_URL}/assets/${product.stripe_id}/${product.photos[0].name}`
          : null;

        let linkProps = { pathname: `/product/${product.url}` };
        if (props.collection)
          linkProps["state"] = { collection: props.collection };

        const sku = prices.find(p => p.stripe_id === product.stripe_id);
        
        return (
          <Link key={i} to={linkProps}>
            <ImgWrapper borderColor={props.theme.palette.secondary.main}>
              <LargeIMG img={url} orientation={props.orientation} />
              {sku && sku.isSoldOut && <SoldOut />}
            </ImgWrapper>
            <Title>
              {product.name}
              <Price>{sku && sku.price}</Price>
            </Title>
          </Link>
        );
      })}
    </Wrapper>
  );
};
export default withTheme(ProductList);