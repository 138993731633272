import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import Paper from '@material-ui/core/Paper';
import HeroBg from "./HeroBg";
import SeeArrows from "./SeeArrows";
import ArrowDivider from "./ArrowDivider";
import JewelryList from "./JewelryList";
import ArtistPieces from "./ArtistPieces";
import MailingListForm from "../ui/MailingListForm";
import Footer from '../ui/Footer';

// const Flex = styled.div`
//   display: flex;
//   color: white;
//   max-width: 1100px;
//   margin: 40px auto 0px;
//   justify-content: space-between;
//   font-family: 'Raleway', sans-serif;
//   text-transform: uppercase;
//   > div { width: 33.3%; }
//   a { color: white; text-decoration: none };
//   a:hover { text-decoration: underline; color: #ff0; }

//   @media (max-width: 1100px) {
//     padding: 0 20px;
//   }
// `;
/*
      <Flex>
        <div>
          <Link to="/what-is-unicode" >What's the deal with Unicode?</Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/shop">Shop the Collection</Link>
        </div>
        <div style={{ textAlign: "right" }}>
          <Link to="/cart">View Cart</Link>
        </div>
      </Flex>
*/

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;

  p, ul {
    font-family: 'Raleway', sans-serif;
    span { text-decoration: underline; }
  }
  li {
    font-size: 14px;
    margin-bottom: 15px;
    b {
      display: block;
      margin-bottom: 5px;
    }
    a { color: inherit; }
  }
  h3 { margin-top: 0; }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    ul { margin-bottom: 20px; }
    font-size: 16px;
  }
`;

const PaperWrapper = styled(Paper)`
  padding: 40px;
  max-width: 1100px;
  margin: 20px auto 80px;
  @media (max-width: 650px) {
    padding: 20px;
  }
`;

const Landing = ({ config, quantity }) => {
  return (
    <>
      <HeroBg quantity={quantity} />
      
      <SeeArrows />

      <ArrowDivider marginTop={0} />

      <ArtistPieces config={config} />

      <ArrowDivider marginTop={0} />
      
      <JewelryList config={config} />

      <ArrowDivider />

      <PaperWrapper>
        <Grid>
          <div>
            <h3>What the Heck is Going On?</h3>
            <ul>
              <li>
                <b>What is Unicode?</b>
                Unicode is how we encode text on the web. It spans everything from letters, numbers, emoji, wingdings, and yes, arrows!!
              </li>
              <li>
                <b>Why are there so many arrows?</b>
                Honestly I'm not sure! The basic arrow forms were added to help users render common symbols in text. Other arrows have been added if they are commonly-used UI symbols, or if they have specific uses in mathematics.
              </li>
              <li>
                <b>Do they let just any symbol into this Unicode club??</b>
                Each addition to the Unicode spec requires a thorough <a href="http://www.unicode.org/pending/proposals.html?utm_source=webtekno">proposal</a> to justify its inclusion as a commonly-used symbol, or neccesary addition to render text online (such as a mathematical proof). The Unicode Consortium votes to decide which proposals to add to the spec. (This is why you see new emoji added every year or so!)
              </li>
              <li>
                <b>Why... did you make Unicode arrow merch?</b>
                I just really love arrows :)
              </li>
            </ul>
          </div>
          <div>
            <h3>Product Drops</h3>
            <p>New items will be added soon, including apparel and original works of art!</p>
            <p style={{ marginBottom: 0 }}>Sign up to be notified when they are added to the site.</p>
            <MailingListForm />

            <h3 style={{ marginTop: "80px" }}>Contact</h3>
            <p>Questions? Get in touch at <span>hi@unicodearrows.com</span></p>
          </div>
        </Grid>
      </PaperWrapper>

      <Footer />
    </>
  );
};

export default Landing;