import React, { useState, useEffect } from 'react';

import { TextField, Select, MenuItem, Button } from '@material-ui/core';
import countryNames from "./country-names.json";

function Shipping(props) {
  const blankAddress = {
    countryCode: "US",
    givenName: "",
    familyName: "",
    address1: "",
    address2: "",
    locality: "",
    region: "",
    postalCode: ""
  }
  const [address, setAddress] = useState(blankAddress);

  useEffect(() => {
    if (Object.keys(props.address).length)
      setAddress(props.address);
  }, [props.address])

  const handleChange = (name, value) => {
    const newAddress = Object.assign({ ...address }, {
      [name]: value
    });
    setAddress(newAddress);
  }

  const setCountry = value => {
    handleChange('countryCode', value);
    if (value === "US") {
      props.setShippingOption(props.shippingOptions.find(t => t.name === "Domestic"))
    } else {
      props.setShippingOption(props.shippingOptions.find(t => t.name === "International"))
    }
  }

  const submitAddress = () => {
    props.createOrder(address);
    props.changePane();
  }

  return (
    <div>
      <Select
        autoComplete="country-name"
        label="Country"
        value={address.countryCode}
        onChange={(e) => setCountry(e.target.value)}
        style={{ marginTop: "20px" }}
        fullWidth
      >
        {countryNames.map((c,i) => (
          <MenuItem value={c.code} key={`country${i}`}>{c.name}</MenuItem>
        ))}
      </Select>
      <TextField
        autoComplete="given-name"
        label="First Name"
        value={address.givenName}
        onChange={(e) => handleChange('givenName', e.target.value)}
        margin="normal"
        style={{ marginRight: "50px" }}
      />
      <TextField
        autoComplete="family-name"
        label="Last Name"
        value={address.familyName}
        onChange={(e) => handleChange('familyName', e.target.value)}
        margin="normal"
      />

      <TextField
        autoComplete="shipping address-line1"
        label={address.countryCode === "US" ? "Street Address" : "Address Line 1"}
        value={address.address1}
        onChange={(e) => handleChange('address1', e.target.value)}
        margin="normal"
        fullWidth
      />
      <TextField
        autoComplete="shipping address-line2"
        label={address.countryCode === "US" ? "Apt, suite, etc (optional)" : "Address Line 2 (Optional)"}
        value={address.address2}
        onChange={(e) => handleChange('address2', e.target.value)}
        margin="normal"
        fullWidth
      />
      <TextField
        autoComplete="shipping locality"
        label="City"
        value={address.locality}
        onChange={(e) => handleChange('locality', e.target.value)}
        margin="normal"
        style={{ marginRight: "50px" }}
      />
      <TextField
        autoComplete="shipping region"
        label={address.countryCode === "US" ? "State" : "Province (Optional)"}
        value={address.region}
        onChange={(e) => handleChange('region', e.target.value)}
        margin="normal"
        style={{ marginRight: "50px" }}
      />
      <TextField
        autoComplete="shipping postal-code"
        label={address.countryCode === "US" ? "Zip Code" : "Postal Code"}
        value={address.postalCode}
        onChange={(e) => handleChange('postalCode', e.target.value)}
        margin="normal"
      />

      <p style={{ fontSize: "14px", fontFamily: "'Raleway', sans-serif", marginTop: "30px", color: "black" }}>
        All US orders will be shipped for FREE with USPS First-Class Mail.
      </p>
      <p style={{ fontSize: "14px", fontFamily: "'Raleway', sans-serif", marginTop: "10px" }}>
        Non-US orders have a $15USD shipping fee.
      </p>

      <div>
        <Button variant="contained" color="primary"
          style={{ marginTop: "30px" }}
          disabled={!address.postalCode.length}
          onClick={submitAddress}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
export default Shipping;