 import React, { useState } from 'react';
import styled from 'styled-components';

import TshirtSizeChart from "./TshirtSizeChart";

import { Button, TextField, Select } from "@material-ui/core";

const Grid = styled.div`
  @media (min-width: 1100px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
`;
const FlexWrapper = styled.div `
  margin-bottom: 40px;
  max-width: 300px;
  width: 100%;
`;
const Row = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  > label {
    line-height: 34px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
  }
`;
const Description = styled.div `
  color: #888;
  margin-bottom: 40px;
  font-family: "Poppins", sans-serif;
  white-space: pre-line;

  a { color: #333; }
`;
const Right = styled.div `
  display: flex;
  flex-direction: row-reverse;
  margin: 30px 0 60px;
  align-items: baseline;
`;
const BulletPoints = styled.div `
  clear: both;
  font-size: 14px;
  margin: 20px 0 40px;
  font-family: 'Raleway', sans-serif;
  > ul {
    margin: 0;
    padding: 0 20px 0;
    > li {
      margin-bottom: 10px;
    }
  }
`;
const AddToCartSection = styled.div`
  @media (min-width: 1100px) {
    border-left: 1px solid #ccc;
    padding-left: 40px;
  }
  @media (max-width: 1100px) {
    border-top: 1px solid #ccc;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
`;

const DetailsArtwork = ({ product, inventory, price, quantity, setQuantity, addToCart, setProduct, variants, updateSkuPrice }) => {

  const selectOption = name => event => {
    const newProduct = Object.assign({ ...product }, {
      [name]: event.target.value
    });
    setProduct(newProduct)

    const index = event.target.selectedIndex;
    const selectedOption = event.target.childNodes[index]
    const sku_id = selectedOption.getAttribute('sku_id');
    const price = selectedOption.getAttribute('price');
    const inventory = selectedOption.getAttribute('inventory');
    if (sku_id)
      updateSkuPrice(sku_id, price, inventory);
  }

  let noAvailableProducts;
  if (inventory && inventory.type !== "infinite" && inventory.quantity === 0) {
    noAvailableProducts = true;
  }

  return (
    <>
      <h2 style={{ marginTop: "0" }}>{product.name}</h2>
      <Grid>
        <div style={{ gridColumn: "span 2" }}>
          <Description>
            {product.description}

            {product.credit && (
              <div style={{ marginTop: "10px" }}>
                Design by <a href={product.credit.site}>{product.credit.name}</a>
              </div>
            )}
          </Description>
          <div>
            { product.type === "t-shirt" ? (
              <TshirtSizeChart product={product} />
            ) : (
              product.bulletPoints && (
                <BulletPoints>
                  <ul>
                    {product.bulletPoints.map((detail,i) =>
                      <li key={i}>{detail}</li>
                    )}
                  </ul>
                </BulletPoints>
              )
            )}
          </div>
        </div>
        <AddToCartSection>
          { variants.length > 1 &&
            <FlexWrapper>
              {variants.map((v,i) => (
                <Row key={`variant${i}`}>
                  <label>{v.name}</label>
                  <Select
                    native
                    value={product[v.name]}
                    onChange={selectOption(v.name)}
                    style={{ width: "155px", fontSize: "14px", height: "29px" }}
                  >
                    { v.options && v.options.map((option,j) => {
                      if (isNaN(option.label)) {
                        option.label = option.label.charAt(0).toUpperCase() + option.label.slice(1);
                      }
                      return (
                        <option key={j}
                          value={option.label}
                          sku_id={option.sku_id}
                          price={option.price}
                          inventory={JSON.stringify(option.inventory)}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </Select>
                </Row>
              ))}
            </FlexWrapper>
          }
      
          <div style={{ fontWeight: "600", textAlign: "right" }}>
            {price && (Number(price)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </div>
          <Right>
            { noAvailableProducts && <div style={{ marginBottom: "10px", fontWeight: "bold" }}>SOLD OUT</div>}
            <Button
              variant="contained" color="primary"
              disabled={noAvailableProducts}
              onClick={() => addToCart(product)}
            >
              Add To Cart
            </Button>
          </Right>
        </AddToCartSection>
      </Grid>

      <Grid>
        
      </Grid>
    </>
  );
};
export default DetailsArtwork;