import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  color: #ccc;
  text-align: center;
  margin-bottom: 40px;
  a { color: #ccc; }
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;

const Footer = () => (
  <Wrapper>
    another site by <a href="https://rachelbinx.com">Rachel Binx</a>
  </Wrapper>
);
export default Footer;