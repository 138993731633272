 import React, { useState } from 'react';

import DetailsJewelry from "./DetailsJewelry";
import DetailsArtwork from "./DetailsArtwork";

const ProductDetails = props => {
  const [product, setProduct] = useState(props.product);

  if (product.type === "jewelry")
    return (
      <DetailsJewelry
        product={product}
        inventory={props.inventory}
        price={props.price}
        quantity={props.quantity}
        setQuantity={props.setQuantity}
        addToCart={props.addToCart}
      />
    )
  else
    return (
      <DetailsArtwork
        product={product}
        setProduct={setProduct}
        variants={props.variants}
        inventory={props.inventory}
        price={props.price}
        quantity={props.quantity}
        setQuantity={props.setQuantity}
        addToCart={props.addToCart}
        updateSkuPrice={props.updateSkuPrice}
      />
    );
};
export default ProductDetails;