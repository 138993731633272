import React from 'react';
import styled from 'styled-components';
import { getProductsFromCollection } from "../../util";

import LandingWrapper from "./LandingWrapper";
import ProductList from '../product/ProductList';

const Wrapper = styled.div`
  background: #ff0;
  padding: 40px;
  margin: 40px -40px -40px;
  @media (max-width: 650px) {
    margin: 40px -20px -20px;
    padding: 20px;
  }
`;
const Grid = styled.div`
  font-family: 'Raleway', sans-serif;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  > div div:first-child {
    margin-bottom: 10px;
    text-decoration: underline;
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    font-size: 14px;
    grid-gap: 20px;
  }
`;
const Title = styled.div`
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
  font-size: 1.2em;
  @media (max-width: 650px) {
    margin-bottom: 20px;
    font-size: 1em;
  }
`;

/*
  <Wrapper>
        <Title>
          Dec 25th Ordering Deadlines
        </Title>
        <Grid>
          <div>
            <div>US & Canada</div>
            <div><b>Dec 8th</b></div>
          </div>
          <div>
            <div>Europe, UK, Australia, Japan, Brazil</div>
            <div><b>Dec 3rd</b></div>
          </div>
          <div>
            <div>Rest of World</div>
            <div>Please email <b>hi@unicodearrows.com</b></div>
          </div>
        </Grid>
      </Wrapper>
*/

const JewelryList = ({ config }) => {
  const featuredProducts = getProductsFromCollection(config, "artist-pieces");

  return (
    <LandingWrapper
      title="high fashion arrow decor"
    >
      <ProductList
        orientation="landscape"
        collection="Artist Pieces"
        products={featuredProducts}
      />
    </LandingWrapper>
  );
};

export default JewelryList;