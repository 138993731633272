import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const P = styled.p`
  color: #888;
`;
const Table = styled.table `
  width: 100%;
  color: #555;
  border-collapse: collapse;
  margin-bottom: 20px;

  td, th { padding: 2px 5px; }
  td { border: 1px solid #eee; text-align: center; }
`;
const Grid = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #888;

  @media (min-width: 650px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
`;

const TshirtSizeChart = ({ product }) => {
  const [type, setType] = useState('Unfitted ("Unisex")')

  useEffect(() => {
    if (!product.Style) return;
    setType(product.Style);
  }, [product.Style])

  return (
    <Grid>
      <div>
        <P><b>{type}</b> Size Guide, in inches</P>
        <Table>
          <thead>
            <tr>
              <th>Size</th>
              <th>Length</th>
              <th>Chest</th>
            </tr>
          </thead>
          <tbody>
            { type === "Fitted" ? (
              <>
                <tr>
                  <td>S</td>
                  <td>26</td>
                  <td>31 - 32</td>
                </tr>
                <tr>
                  <td>M</td>
                  <td>26 ½</td>
                  <td>33 - 34</td>
                </tr>
                <tr>
                  <td>L</td>
                  <td>27 ⅛</td>
                  <td>35 - 36</td>
                </tr>
                <tr>
                  <td>XL</td>
                  <td>27 ¾</td>
                  <td>36 - 37</td>
                </tr>
                <tr>
                  <td>2XL</td>
                  <td>28 ⅜</td>
                  <td>38 - 39</td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>S</td>
                  <td>28</td>
                  <td>36 - 39</td>
                </tr>
                <tr>
                  <td>M</td>
                  <td>29</td>
                  <td>39 - 42</td>
                </tr>
                <tr>
                  <td>L</td>
                  <td>31</td>
                  <td>42 - 45</td>
                </tr>
                <tr>
                  <td>XL</td>
                  <td>32</td>
                  <td>46 - 49</td>
                </tr>
                <tr>
                  <td>2XL</td>
                  <td>33</td>
                  <td>50 - 53</td>
                </tr>
              </>
            ) }
          </tbody>
        </Table>
      </div>
      <div>
        <ul>
          { type === "Fitted" ? (
            <>
              <li>50% polyester, 25% cotton, 25% rayon</li>
              <li>Slim fit, with pre-shrunk fabric</li>
              <li>Ribbed crew neck with set-in sleeves</li>
            </>
          ) : (
            <>
              <li>Runs large, please check the measurements</li>
              <li>60% organic cotton, 40% recycled poly rPET</li>
              <li>Each shirt saves at least 4 plastic bottles from ending up in a landfill!</li>
            </>
          )}
        </ul>
      </div>
    </Grid>
  );
};
export default TshirtSizeChart;