import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import Paper from '@material-ui/core/Paper';
import ProductList from '../product/ProductList';

const Underline = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px auto 0;
`;
const ShopTitle = styled.div`
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;

  a {
    text-decoration: none;
    color: black;
    padding-top: 3px;
    &:hover { background-color: rgba(255,255,0,.2); }
  }
  span {
    font-size: 48px;
    line-height: 20px;
    vertical-align: sub;
    &:last-child { margin-left: 10px; }
    &:first-child { margin-right: 10px; }
  }
  div {
    text-align: center;
    max-width: 80%;
  }
  @media (max-width: 650px) {
    font-size: 18px;
    span { font-size: 26px; }
  }
`;
const PaperWrapper = styled(Paper)`
  padding: 40px;
  max-width: 1100px;
  margin: 20px auto 20px;
  @media (max-width: 650px) {
    padding: 20px;
  }
`;

const LandingWrapper = ({ title, linkTitle, link, children }) => {
  return (
    <PaperWrapper>
      <ShopTitle style={{ marginBottom: "40px" }} >
        <span>↬</span>
        <div>{title}</div>
        <span>↫</span>
      </ShopTitle>

      { children }

      { link && (
        <Underline>
          <ShopTitle style={{ borderBottom: "3px solid #ff0" }}>
            <span>➠</span>
            <Link to={link}>
              {linkTitle}
            </Link>
            <span>➠</span>
          </ShopTitle>
        </Underline>
      )}
    </PaperWrapper>
  );
};

export default LandingWrapper;