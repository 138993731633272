import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';

import PageWrapper from './ui/PageWrapper';
import ArrowList from "./landing/ArrowList";
import Paper from '@material-ui/core/Paper';

function AllArrows(props) {
  return (
    <PageWrapper>
      <Paper style={{ padding: "40px" }}>
        <ArrowList />
      </Paper>
    </PageWrapper>
  );
}

export default AllArrows;