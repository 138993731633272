import React, { useState, useEffect } from 'react';

import { TextField, Button } from '@material-ui/core';

function MailingListForm() {
  const [email, setEmail] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);

  const signUp = () => {
    fetch('/mailing-list/signup', {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        credentials: 'same-origin',
        body: JSON.stringify({ email })
      })
      .then(res => res.json())
      .then(json => {
        setSubscriptionStatus(json.subscribed);
      });
  }

  return (
    <div>
      { subscriptionStatus ? (
        <div style={{ marginTop: "40px", fontWeight: "bold" }}>
          Thanks, you have been subscribed! :)
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            autoComplete="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            style={{ flex: 1, marginRight: "20px" }}
          />
          <Button variant="contained" color="primary"
            style={{ marginTop: "20px" }}
            disabled={!email.length}
            onClick={signUp}
          >
            Subscribe
          </Button>
        </div>
      )}
    </div>
  );
}
export default MailingListForm;