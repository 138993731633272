import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import LandingWrapper from "./LandingWrapper";
import ArrowList from "./ArrowList";

const Wrapper = styled.div`
  margin-top: 420px;
  @media (max-width: 650px) {
    margin-top: 300px;
  }
`;

const SeeArrows = () => (
  <Wrapper>
    <LandingWrapper
      title="welcome to the best part of the unicode spec"
      link="/all-arrows"
      linkTitle="see all arrows"
    >
      <ArrowList maxHeight="430px" />
    </LandingWrapper>
  </Wrapper>
);

export default SeeArrows;