import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: ${props => props.maxHeight ? "hidden" : "inherit" };
  max-height: ${props => props.maxHeight};
  margin: ${props => props.maxHeight ? "-20px 0" : 0 };
`;
const Box = styled.div`
  width: 78px;
  height: 78px;
  border: 1px solid #ccc;
  color: black;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 -1px -1px 0;

  @media (max-width: 650px) {
    width: 60px;
    height: 60px;
  }
`;
const Code = styled.div`
  color: #888;
  font-size: 14px;
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;
const Arrow = styled.div`
  font-size: 42px;
  line-height: 48px;
  @media (max-width: 650px) {
    font-size: 32px;
    line-height: 30px;
  }
`;

function ArrowList({ maxHeight }) {
  const [arrowList, setArrowList] = useState([]);

  useEffect(() => {
    let list = [];
    // arrow block
    for (let i=0x2190; i < 0x21FF; i++) {
      list.push(i.toString(16));
    }
    // supplemental arrows A
    for (let i=0x27F0; i < 0x27FF; i++) {
      list.push(i.toString(16));
    }
    // supplemental arrows B
    for (let i=0x2900; i < 0x297F; i++) {
      list.push(i.toString(16));
    }

    setArrowList(list)
  }, []);

  return (
    <Flex maxHeight={maxHeight}>
      { arrowList.map((a,i) => (
        <Box key={`a${i}`}>
          <Code>{`U+${a}`}</Code>
          <Arrow>{String.fromCharCode(`0x${a}`)}</Arrow>
        </Box>
      ))}
    </Flex>
  );
};

export default ArrowList;