 import React, { useState } from 'react';
import styled from 'styled-components';

import MailingListForm from "../ui/MailingListForm";

import { Button, TextField, Select } from "@material-ui/core";

const FlexWrapper = styled.div `
  margin: 20px 0 40px;
`;
const Row = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  > label {
    line-height: 34px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
  }
`;
const Right = styled.div `
  display: flex;
  flex-direction: row-reverse;
  margin: 30px 0 60px;
  align-items: baseline;
`;
const JewelryDescription = styled.div `
  color: #888;
  font-size: 32px;
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 60px;
`;
const BulletPoints = styled.div `
  clear: both;
  font-size: 14px;
  margin: 20px 0 40px;
  font-family: 'Raleway', sans-serif;
  > ul {
    margin: 0;
    padding: 0 20px 0;
    > li {
      margin-bottom: 10px;
    }
  }
`;

const DetailsJewelry = ({ product, inventory, price, quantity, setQuantity, addToCart }) => {
  let inventoryStatus, noAvailableProducts;
  if (inventory && inventory.type !== "infinite") {
    if (inventory.quantity === 0) {
      inventoryStatus = <div style={{ marginBottom: "10px", fontWeight: "bold" }}>SOLD OUT</div>;
      noAvailableProducts = true;
    } else if (inventory.quantity < 3) {
      inventoryStatus = <div style={{ marginBottom: "10px" }}>{`Hurry! Only ${inventory.quantity} Available`}</div>;
    }
  }

  const char = String.fromCharCode(`0x${product.name.split(" ")[0].split("+")[1]}`);

  return (
    <>
      <h2 style={{ marginTop: "0" }}>{char} {product.name} {char}</h2>
      <JewelryDescription>{product.description}</JewelryDescription>
      { product.bulletPoints &&
        <BulletPoints>
          <ul>
            {product.bulletPoints.map((detail,i) =>
              <li key={i}>{detail}</li>
            )}
          </ul>
        </BulletPoints>
      }
      <div style={{ textAlign: "right", fontSize: "14px", "color": "#555" }}>
        { inventoryStatus }
      </div>
      <div style={{ fontWeight: "600", textAlign: "right" }}>
        {price && (Number(price)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </div>
      <Right>
        <Button
          variant="contained" color="primary"
          disabled={noAvailableProducts}
          onClick={() => addToCart(product)}
        >
          Add To Cart
        </Button>
        {!noAvailableProducts && (
          <TextField
            value={quantity}
            onChange={e => setQuantity(e.target.value)}
            type="number"
            inputProps={{ min: "1", step: "1", max: inventory ? inventory.quantity : null }}
            margin="normal"
            style={{ width: "40px", margin: "0 30px 0" }}
          />
        )}
      </Right>

      {noAvailableProducts && (
        <div style={{ background: "#ff0", padding: "20px", fontSize: "16px" }}>
          Subscribe to our mailing list to be notified when this design is back in stock.
          <MailingListForm />
        </div>
      )}
    </>
  );
};
export default DetailsJewelry;