import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import PageWrapper from '../ui/PageWrapper';
import Paper from '@material-ui/core/Paper';
import ProductList from './ProductList';

const Wrapper = styled.div `
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const IMG = styled.div `
  background-color: #ddd;
  width: 100%;
  padding-bottom: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: inline-block;
  margin-bottom: 10px;
`;
const TitleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
`;
const Title = styled.div`
  color: white;
  text-decoration: none;
  font-size: 60px;
  font-weight: bold;
  background-color: rgba(0,0,0,.6);
  border-radius: 10px;
  padding: 0 20px;
`;

const CollectionList = ({ config }) => {
  let displayCollections = [...config.collections]
    .filter(f => f.url !== "featured-products");

  // <h2 style={{ marginTop: 0, fontWeight: 600, color: "black" }}>All Products</h2>

  if (!!!displayCollections.length) {
    return (
      <PageWrapper>
        <Paper style={{ padding: "40px" }}>
          <ProductList products={config.products} collection={"All Products"} />
        </Paper>
      </PageWrapper>
    )
  }


  return (
    <PageWrapper>
      <Paper style={{ padding: "40px" }}>
        <Wrapper>
          { displayCollections.map((c,i) => (
            <Link key={`type${i}`}
              to={`/collection/${c.url}`}
              style={{ position: "relative" }}
            >
              <IMG style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/collection-${c.id}/${c.cover})` }} />
              <TitleWrapper>
                <Title>{c.name}</Title>
              </TitleWrapper>
            </Link>
          ))}
        </Wrapper>
      </Paper>
    </PageWrapper>
  );
};
export default CollectionList;