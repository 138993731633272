import React from 'react';
import styled from 'styled-components';
import { getProductsFromCollection } from "../../util";

import LandingWrapper from "./LandingWrapper";
import ProductList from '../product/ProductList';

const JewelryList = ({ config }) => {
  const featuredProducts = getProductsFromCollection(config, "featured-products");

  return (
    <LandingWrapper
      title="add a lil arrow bling to your life"
      link="/collection/jewelry"
      linkTitle="shop all arrow jewelry"
    >
      <ProductList orientation="portrait" products={featuredProducts} />
    </LandingWrapper>
  );
};

export default JewelryList;